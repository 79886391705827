import React from 'react';
import { Link } from 'react-router-dom';

function Privacy() {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
      
      <div className="space-y-6">
        <section>
          <h2 className="text-xl font-semibold mb-3">Information We Collect</h2>
          <p className="text-gray-600">
            We collect information that you provide directly to us, including when you create an account,
            use our services, or communicate with us.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3">How We Use Your Information</h2>
          <p className="text-gray-600">
            We use the information we collect to provide and improve our services,
            communicate with you, and ensure the security of your account.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3">Data Security</h2>
          <p className="text-gray-600">
            We implement appropriate technical and organizational measures to protect
            your personal information against unauthorized access or disclosure.
          </p>
        </section>
      </div>

      <div className="mt-8">
        <Link to="/" className="text-blue-600 hover:text-blue-800">
          ← Back to Home
        </Link>
      </div>
    </div>
  );
}

export default Privacy;
