import { ApiError } from '../utils/errors';
import { validateApiName } from '../utils/validation';
import { config } from '../config';

const API_URL = config.apiUrl;
const PROMPT_ENDPOINT = '/prompt';
const COLLECTIONS_ENDPOINT = '/collections';

const system_prompt = "Today's date is " + calculate_current_date() + "."

const gpt_messages = [
  {
    "role": "system",
    "content": system_prompt
  },
];

function calculate_current_date() {
  let date = new Date();
  let dd = String(date.getDate()).padStart(2, '0');
  let mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = date.getFullYear();

  console.log(mm + '/' + dd + '/' + yyyy)
  return mm + '/' + dd + '/' + yyyy;
}

export const getApiDefinitions = async (tenant_id, collection_id, headers = { 'Content-Type': 'application/json' }) =>  {
  try {
    if (!tenant_id || !collection_id) {
      console.error("Tenant ID and Collection ID are required");
      return [];
    }

    const url = new URL(`${API_URL}${COLLECTIONS_ENDPOINT}/${tenant_id}/${collection_id}/apis`);
    
    const response = await fetch(url.toString(), {
      method: 'GET',
      headers: headers
    });
    
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;

  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    return [];
  }
}

export const sendMessageToAPI = async (userMessage, api_definitions, headers = { 'Content-Type': 'application/json' }) => {
  try {
      const functionCalls = [];
      // Only add user message once
      if (gpt_messages[gpt_messages.length - 1]?.content !== userMessage) {
          gpt_messages.push({"role": "user", "content": userMessage});
      }
      
      const response = await fetch(API_URL + PROMPT_ENDPOINT, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ messages: gpt_messages, api_definitions: api_definitions }),
      });

      if (!response.ok) {
          const errorDetails = await response.json(); 
          console.error("Detailed error: ", errorDetails);
          throw new Error('Network response was not ok');
      }

      const data = await response.json();
      const assistantMessage = data.choices[0].message;
      
      if (assistantMessage.tool_calls) {
          // Store the assistant message with tool calls
          gpt_messages.push(assistantMessage);
          
          const functionResults = await Promise.all(assistantMessage.tool_calls.map(async (toolCall) => {
              const functionCall = {
                  name: toolCall.function.name,
                  arguments: JSON.parse(toolCall.function.arguments)
              };

              // Track the API call
              const apiCallDetails = {
                request: functionCall.arguments,
                response: null,
                timestamp: new Date().toISOString(),
                name: functionCall.name,
                url: null,
                authDetails: null
            };
            
            try {
                const result = await executeFunctionCall(functionCall, api_definitions);
                apiCallDetails.response = result.data;
                apiCallDetails.url = result.url;
                apiCallDetails.authDetails = result.authDetails;
                functionCalls.push(apiCallDetails);
            
                return {
                    tool_call_id: toolCall.id,
                    name: toolCall.function.name,
                    content: result.data
                };
              } catch (error) {
                  console.error(`Error executing function ${functionCall.name}:`, error);
                  apiCallDetails.error = error.message;
                  functionCalls.push(apiCallDetails);
                  throw error;
              }
          }));

          // Add function results to conversation history
          functionResults.forEach(result => {
              gpt_messages.push({
                  "role": "tool",
                  "tool_call_id": result.tool_call_id,
                  "name": result.name,
                  "content": JSON.stringify(result.content)
              });
          });

          const functionResponse = await fetch(API_URL + PROMPT_ENDPOINT + '/function_result', {
              method: 'POST',
              headers: headers,
              body: JSON.stringify({
                  messages: gpt_messages,
                  function_results: functionResults,
                  api_definitions: api_definitions
              }),
          });

          if (!functionResponse.ok) {
              throw new Error('Function result submission failed');
          }

          const finalResponse = await functionResponse.json();
          const finalMessage = finalResponse.choices[0].message;
          
          if (finalMessage.tool_calls) {
              // Pass the accumulated function calls when recursing
              const recursiveResult = await sendMessageToAPI(userMessage, api_definitions);
              return {
                  message: recursiveResult.message,
                  functionCalls: [...functionCalls, ...(recursiveResult.functionCalls || [])]
              };
          } else {
              gpt_messages.push({"role": "assistant", "content": finalMessage.content});
              return {
                  message: finalMessage.content,
                  functionCalls: functionCalls
              };
          }
      }

      // Handle normal response without tool calls
      gpt_messages.push({"role": "assistant", "content": assistantMessage.content});
      return {
          message: assistantMessage.content,
          functionCalls: functionCalls.length > 0 ? functionCalls : null
      };

  } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      throw error;
  }
};

async function executeFunctionCall(functionCall, api_definitions) {
  const { name, arguments: args } = functionCall;
  
  const apiDef = api_definitions.find(api => api.name === name);
  if (!apiDef) {
      throw new Error(`No API definition found for function: ${name}`);
  }

  const method = apiDef.http_method || 'GET';
  let endpoint = apiDef.endpoint;
  
  // Handle URL parameters for GET requests
  if (method === 'GET') {
    // console.log("apiDef.request", apiDef.request);
    if (apiDef.request?.url_params) {
      // Replace URL parameters with actual values
      Object.entries(args).forEach(([key, value]) => {
        endpoint = endpoint.replace(`{${key}}`, encodeURIComponent(value));
      });
    } else {
      // Use query parameters
      const url = new URL(endpoint);
      Object.entries(args).forEach(([key, value]) => {
        url.searchParams.append(key, value);
      });
      endpoint = url.toString();
    }
  }

  // Prepare fetch options
  const fetchOptions = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      ...(apiDef.auth_headers || {})
    }
  };

  // Only add body for non-GET requests
  if (method !== 'GET' && args) {
    fetchOptions.body = JSON.stringify(args);
  }

  // Execute the API call
  const response = await fetch(endpoint, fetchOptions);

  if (!response.ok) {
    throw new Error(`API call failed: ${response.statusText}`);
  }

  const responseData = await response.json();

  return {
    data: responseData,
    url: endpoint,
    authDetails: apiDef.auth_headers ? {
      type: 'Bearer Token',
      headers: Object.keys(apiDef.auth_headers)
    } : null
  };
}

// Get collections for the user's tenant Id
export const getTenantCollections = async (tenant_id) => {
  try {
    const response = await fetch(API_URL + COLLECTIONS_ENDPOINT + "/" + tenant_id);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    return "There was a problem with the Get Tenant operation.";
  }
};

// Get API definitions for a particular collection
export const getApiDefinitionsForCollection = async (tenant_id, collection_id) => {
  try {
    const response = await fetch(API_URL + COLLECTIONS_ENDPOINT + "/" + tenant_id + "/" + collection_id);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    return "There was a problem with the Get API Definitions operation.";
  }
};

export const createCollection = async (tenantId, collection) => {
  try {
    if (!tenantId) {
      throw new ApiError('Tenant ID is required', 400);
    }

    if (!collection.name?.trim()) {
      throw new ApiError('Collection name is required', 400);
    }

    const response = await fetch(`${API_URL}/collections`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        tenant_id: tenantId,
        collection_name: collection.name.trim(),
        collection_description: collection.description?.trim(),
        auth_headers: collection.authHeaders,
        apis: []
      }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new ApiError(
        data.detail || 'Failed to create collection',
        response.status,
        data.errors
      );
    }

    return data;
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    }
    throw new ApiError('Failed to create collection', 500, error.message);
  }
};

export const createApi = async (tenantId, collectionId, api) => {
  try {
    // Validation
    if (!tenantId || !collectionId) {
      throw new ApiError('Tenant ID and Collection ID are required', 400);
    }

    if (!api.name?.trim()) {
      throw new ApiError('API name is required', 400);
    }

    // Validate API name format
    if (!validateApiName(api.name)) {
      throw new ApiError(
        'API name can only contain letters, numbers, underscores, and hyphens',
        400
      );
    }

    if (!api.endpoint?.trim()) {
      throw new ApiError('API endpoint is required', 400);
    }

    // Use the request and response fields from the api.request and api.response objects
    const requestBody = {
      name: api.name.trim(),
      endpoint: api.endpoint.trim(),
      http_method: api.method || 'POST',
      description: api.description?.trim(),
      auth_headers: api.authHeaders,
      request: {
        fields: api.request.fields,  // Use the prepared fields from api.request
        url_params: api.request.url_params
      },
      response: {
        fields: api.response.fields  // Use the prepared fields from api.response
      }
    };

    const response = await fetch(`${API_URL}/collections/${tenantId}/${collectionId}/apis`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new ApiError(
        data.detail || 'Failed to create API',
        response.status,
        data.errors
      );
    }

    return data;
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    }
    throw new ApiError('Failed to create API', 500, error.message);
  }
};

export const getCollections = async (tenantId) => {
  try {
    const response = await fetch(`${API_URL}/collections/${tenantId}`);
    if (!response.ok) {
      throw new ApiError(
        'Failed to fetch collections',
        response.status
      );
    }
    const data = await response.json();
    return Array.isArray(data) ? data : [];  // Ensure we always return an array
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    }
    throw new ApiError('Failed to fetch collections', 500, error.message);
  }
};

export const updateApi = async (tenantId, collectionId, apiId, api) => {
  try {
    if (!tenantId || !collectionId || !apiId) {
      throw new ApiError('Tenant ID, Collection ID, and API ID are required', 400);
    }

    // Format the request body to match the backend expectations
    const requestBody = {
      name: api.name.trim(),
      endpoint: api.endpoint.trim(),
      http_method: api.http_method || 'POST',  // Use http_method from the form
      description: api.description?.trim(),
      auth_headers: api.authHeaders,
      request: {
        fields: api.request.fields,
        url_params: api.request.url_params
      },
      response: {
        fields: api.response.fields
      }
    };

    const response = await fetch(`${API_URL}/collections/${tenantId}/${collectionId}/apis/${apiId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),  // Send the formatted request body
    });

    const data = await response.json();

    if (!response.ok) {
      throw new ApiError(
        data.detail || 'Failed to update API',
        response.status,
        data.errors
      );
    }

    return data;
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    }
    throw new ApiError('Failed to update API', 500, error.message);
  }
};

export const deleteApi = async (tenantId, collectionId, apiId) => {
  try {
    if (!tenantId || !collectionId || !apiId) {
      throw new ApiError('Tenant ID, Collection ID, and API ID are required', 400);
    }

    const response = await fetch(`${API_URL}/collections/${tenantId}/${collectionId}/apis/${apiId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      const data = await response.json();
      throw new ApiError(
        data.detail || 'Failed to delete API',
        response.status,
        data.errors
      );
    }

    return true;
  } catch (error) {
    if (error instanceof ApiError) {
      throw error;
    }
    throw new ApiError('Failed to delete API', 500, error.message);
  }
};
