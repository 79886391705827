import React from 'react'
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card"
import { Button } from "./ui/button"

export default function LearnMoreSection() {
  return (
    <section className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        {/* Hero Image Section */}
        <div className="max-w-7xl mx-auto mb-16">
          <img 
            src="/demo-image-tenxer.png" 
            alt="TenXer Product Demo" 
            className="w-full rounded-lg shadow-xl"
          />
        </div>

        {/* Main Description */}
        <div className="max-w-7xl mx-auto mb-16 py-2 md:py-4 lg:py-8 xl:py-10">
          <h2 className="text-4xl font-bold text-center mb-6">
            Transform Your APIs Into Conversations
          </h2>
          <p className="text-gray-600 text-lg text-center leading-relaxed">
            TenXer takes your APIs and turns them into conversations. Within minutes deploy
            a chat instance that can help you 10x your productivity, whether you're a developer, product manager, or business analyst. 
            Ask the assistant to retrieve records, loop through results, calculate metrics, and anything else you can imagine.
          </p>
        </div>
        
        {/* Cards Section */}
        <div className="max-w-7xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 justify-items-stretch">
            {/* Development Card */}
            <Card className="w-full">
              <CardHeader>
                <CardTitle>For Development</CardTitle>
              </CardHeader>
              <CardContent>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  <li>Skip Postman - no more manual API requests!</li>
                  <li>Test localhost and production APIs, even on VPN</li>
                  <li>No code or scripting! Talk to your systems directly</li>
                </ul>
              </CardContent>
            </Card>

            {/* Product Card */}
            <Card className="w-full">
              <CardHeader>
                <CardTitle>For Product</CardTitle>
              </CardHeader>
              <CardContent>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  <li>Query business metrics quickly and efficiently</li>
                  <li>Reduce burden on engineering</li>
                  <li>Test features faster, with real data</li>
                </ul>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </section>
  )
}