import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { XCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/24/solid';

const Error = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const error = query.get('error');
  const defaultMessage = 'Sorry, we couldn\'t find what you\'re looking for.';
  const message = query.get('message') || defaultMessage;

  return (
    <section className="relative">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-extrabold leading-tighter tracking-tighter mb-4" data-aos="zoom-y-out">
              {error ? 'Error' : '404'}
            </h1>
            <div className="max-w-xl mx-auto">
              <div className="mt-8 bg-white shadow-lg rounded-sm p-6">
                <div className="flex flex-col items-center justify-center">
                  {error ? (
                    <div className="flex items-center text-red-600 mb-4">
                      <XCircleIcon className="w-8 h-8 mr-2" />
                      <p className="text-xl font-semibold">{message}</p>
                    </div>
                  ) : (
                    <div className="flex items-center text-amber-600 mb-4">
                      <ExclamationTriangleIcon className="w-8 h-8 mr-2" />
                      <p className="text-xl font-semibold">{message}</p>
                    </div>
                  )}
                  <Link 
                    to="/" 
                    className="btn-primary"
                  >
                    Return Home
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error;
