import React, { useState } from 'react';
import { createCollection } from '../services/apiClient';
import { getErrorMessage } from '../utils/errors';

export default function CollectionForm({ tenantId, onSubmit, onCancel }) {
  const [collection, setCollection] = useState({ 
    name: '', 
    description: '', 
    authHeaders: {} 
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);

    try {
      const result = await createCollection(tenantId, collection);
      onSubmit(result);
    } catch (error) {
      setError(getErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {error && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
              </svg>
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error}</p>
            </div>
          </div>
        </div>
      )}

      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
        <input
          type="text"
          id="name"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none bg-white shadow-sm"
          value={collection.name}
          onChange={(e) => setCollection({...collection, name: e.target.value})}
          required
        />
      </div>
      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
        <textarea
          id="description"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none bg-white shadow-sm"
          value={collection.description}
          onChange={(e) => setCollection({...collection, description: e.target.value})}
        />
      </div>
      <div>
        <label htmlFor="authHeaders" className="block text-sm font-medium text-gray-700">Optional Headers (JSON) - These will be added to every request</label>
        <textarea
          id="authHeaders"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none bg-white shadow-sm"
          value={JSON.stringify(collection.authHeaders, null, 2)}
          onChange={(e) => {
            try {
              const parsed = JSON.parse(e.target.value);
              setCollection({...collection, authHeaders: parsed});
            } catch (error) {
              // Handle invalid JSON input
            }
          }}
        />
      </div>
      <div className="flex justify-end space-x-2">
        <button 
          type="button" 
          className="btn-secondary" 
          onClick={onCancel}
          disabled={isLoading}
        >
          Cancel
        </button>
        <button 
          type="submit" 
          className={`btn-primary ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
          disabled={isLoading}
        >
          {isLoading ? 'Creating...' : 'Create Collection'}
        </button>
      </div>
    </form>
  );
}
