export const getAuthHeaders = (token = null) => {
  const headers = {
    'Content-Type': 'application/json'
  };

  if (process.env.REACT_APP_ENV !== 'local' && token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
};
