import React, { forwardRef, useImperativeHandle, useState } from 'react';

const RequestFieldForm = forwardRef(({ onAdd, method, urlParamsEnabled, onUrlParamsChange }, ref) => {
  const [field, setField] = useState({
    name: '', 
    type: 'string', 
    description: '', 
    required: false,
    url_params: false
  });

  // Expose the current field value to the parent
  useImperativeHandle(ref, () => ({
    getCurrentField: () => ({
      ...field,
      url_params: urlParamsEnabled,
      required: urlParamsEnabled ? true : field.required
    })
  }));

  const handleSubmit = () => {
    if (!field.name.trim()) return;
  
    onAdd({
      ...field,
      required: urlParamsEnabled ? true : field.required,
      url_params: urlParamsEnabled
    });

    setField({
      name: '', 
      type: 'string', 
      description: '', 
      required: false 
    });
  };

  const handleRequiredChange = (e) => {
    setField(prev => ({
      ...prev,
      required: e.target.checked
    }));
  };

  return (
    <div className="space-y-4">
      {method === 'GET' && (
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            id="urlParams"
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            checked={urlParamsEnabled}
            onChange={(e) => onUrlParamsChange(e.target.checked)}
          />
          <label htmlFor="urlParams" className="ml-2 block text-sm text-gray-900">
            Use URL parameters (e.g., /api/{'{param}'})
          </label>
        </div>
      )}

      <div>
        <label htmlFor="fieldName" className="block text-sm font-medium text-gray-700">
          {urlParamsEnabled ? 'URL Parameter Name' : 'Field Name'}
        </label>
        <input
          type="text"
          id="fieldName"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none bg-white shadow-sm"
          value={field.name}
          onChange={(e) => setField({...field, name: e.target.value})}
          required
        />
      </div>

      {/* Only show type selector if not using URL parameters */}
      {!urlParamsEnabled && (
        <div>
          <label htmlFor="fieldType" className="block text-sm font-medium text-gray-700">Field Type</label>
          <select
            id="fieldType"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none bg-white shadow-sm"
            value={field.type}
            onChange={(e) => setField({...field, type: e.target.value})}
          >
            <option value="string">String</option>
            <option value="integer">Integer</option>
            <option value="boolean">Boolean</option>
            <option value="array">Array</option>
            <option value="object">Object</option>
          </select>
        </div>
      )}

      <div>
        <label htmlFor="fieldDescription" className="block text-sm font-medium text-gray-700">Description</label>
        <textarea
          id="fieldDescription"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none bg-white shadow-sm"
          value={field.description}
          onChange={(e) => setField({...field, description: e.target.value})}
        />
      </div>

      {/* Only show required checkbox if not using URL parameters */}
      {!urlParamsEnabled && (
        <div className="flex items-center">
          <input
            type="checkbox"
            id="fieldRequired"
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            checked={field.required}
            onChange={handleRequiredChange}
          />
          <label htmlFor="fieldRequired" className="ml-2 block text-sm text-gray-900">
            Required
          </label>
        </div>
      )}

      <div className="flex justify-end">
        <button 
          type="button"
          onClick={handleSubmit}
          className="btn-primary"
        >
          Add {urlParamsEnabled ? 'Parameter' : 'Field'}
        </button>
      </div>
    </div>
  );
});

export default RequestFieldForm;
