import React, { useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './assets/css/style.css';
import './assets/css/App.css';

import { Auth0Provider } from '@auth0/auth0-react';
import Header from './components/Header';
import Home from './views/Home';
import Login from './views/Login';
import StatusPage from './views/StatusPage';
import Account from './views/Account';
import ChatPage from './views/ChatPage';
import DemoChatPage from './components/DemoChatPage';
import Privacy from './views/Privacy';
import Terms from './views/Terms';
import Error from './views/Error';

function App() {
  const location = useLocation();
  const navigate = useNavigate();

  const onRedirectCallback = (appState) => {
    const searchParams = new URLSearchParams(window.location.search);
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');
  
    if (error) {
      navigate(`/status?error=${error}&message=${encodeURIComponent(errorDescription)}`);
    } else {
      const targetUrl = appState?.targetUrl || '/';
      navigate(targetUrl);
    }
  };

  // AOS initialization
  useEffect(() => {
    AOS.init({
      once: true,
      disable: 'phone',
      duration: 700,
      easing: 'ease-out-cubic',
    });
  }, []);

  // Scroll to top on route change
  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
  }, [location.pathname]);

  return (
    <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    authorizationParams={{
      redirect_uri: `${window.location.origin}/status`,
      scope: 'openid profile email offline_access',
      audience: process.env.REACT_APP_AUTH0_AUDIENCE
    }}
    cacheLocation="localstorage"  // Ensure the user stays logged in
    useRefreshTokens={true}  // Use refresh tokens to extend session
    onRedirectCallback={onRedirectCallback}
  >
    <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/status" element={<StatusPage />} />
        <Route path="/account" element={<Account />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/chat/:collectionId" element={<ChatPage />} />
        <Route path="/demo" element={<DemoChatPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/error" element={<Error />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/privacypolicy" element={<Privacy />} />
        <Route path="/tos" element={<Terms />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="*" element={<Error />} /> {/* Catch all unmatched routes */}
      </Routes>
      </Auth0Provider>
  );
}

export default App;
