import React, { forwardRef, useImperativeHandle, useState } from 'react';

const ResponseFieldForm = forwardRef(({ onAdd }, ref) => {
  const [field, setField] = useState({ 
    name: '', 
    type: 'string', 
    description: '' 
  });

  // Expose the current field value to the parent
  useImperativeHandle(ref, () => ({
    getCurrentField: () => field
  }));

  const handleSubmit = () => {
    if (!field.name.trim()) return;
    
    onAdd({...field});
    
    setField({
      name: '',
      type: 'string',
      description: ''
    });
  };

  return (
    <div className="space-y-4">
      <div>
        <label htmlFor="fieldName" className="block text-sm font-medium text-gray-700">Field Name</label>
        <input
          type="text"
          id="fieldName"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none bg-white shadow-sm"
          value={field.name}
          onChange={(e) => setField({...field, name: e.target.value})}
          required
        />
      </div>
      <div>
        <label htmlFor="fieldType" className="block text-sm font-medium text-gray-700">Field Type</label>
        <select
          id="fieldType"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none bg-white shadow-sm"
          value={field.type}
          onChange={(e) => setField({...field, type: e.target.value})}
        >
          <option value="string">String</option>
          <option value="integer">Integer</option>
          <option value="boolean">Boolean</option>
          <option value="array">Array</option>
          <option value="object">Object</option>
        </select>
      </div>
      <div>
        <label htmlFor="fieldDescription" className="block text-sm font-medium text-gray-700">Description</label>
        <textarea
          id="fieldDescription"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md appearance-none bg-white shadow-sm"
          value={field.description}
          onChange={(e) => setField({...field, description: e.target.value})}
        />
      </div>
      <div className="flex justify-end">
        <button 
          type="button"
          onClick={handleSubmit}
          className="btn-primary"
        >
          Add Field
        </button>
      </div>
    </div>
  );
});

export default ResponseFieldForm;
