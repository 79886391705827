import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react"; 

export default function Login() {
  const { loginWithRedirect, isAuthenticated } = useAuth0(); 
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/account');
    } else {
      loginWithRedirect({
        appState: { targetUrl: location.pathname },
      });
    }
  }, [isAuthenticated, navigate, location.pathname, loginWithRedirect]);

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-[#3490dc] to-[#38b2ac]">
      <div className="bg-white p-8 rounded-lg shadow-xl max-w-md w-full mx-4">
        <div className="flex flex-col items-center">
          <div className="animate-spin rounded-full h-12 w-12 border-4 border-[#3490dc] border-t-transparent mb-4"></div>
          <h2 className="text-2xl font-semibold text-gray-800 mb-2">Redirecting to Login</h2>
          <p className="text-gray-600 text-center">
            Please wait while we securely redirect you to the login page...
          </p>
        </div>
      </div>
    </div>
  );
}