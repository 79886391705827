import React from 'react';
import { Link } from 'react-router-dom';

function Terms() {
  return (
    <div className="max-w-3xl mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-6">Terms of Service</h1>
      
      <div className="space-y-6">
        <section>
          <h2 className="text-xl font-semibold mb-3">Acceptance of Terms</h2>
          <p className="text-gray-600">
            By accessing and using our services, you agree to be bound by these Terms of Service
            and all applicable laws and regulations.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3">Use License</h2>
          <p className="text-gray-600">
            We grant you a limited, non-exclusive, non-transferable license to use our services
            for your personal or business purposes, subject to these terms.
          </p>
        </section>

        <section>
          <h2 className="text-xl font-semibold mb-3">Limitations</h2>
          <p className="text-gray-600">
            You may not: (1) modify or copy our materials; (2) use the materials for any commercial purpose;
            (3) attempt to reverse engineer any software contained in our service.
          </p>
        </section>
      </div>

      <div className="mt-8">
        <Link to="/" className="text-blue-600 hover:text-blue-800">
          ← Back to Home
        </Link>
      </div>
    </div>
  );
}

export default Terms;
